
import { mapState, mapActions } from 'pinia'
import launch from '~/static/animations/launch.json'
import { usePricingStore } from '~/store/pricing'
import { useSiteStore } from '~/store/site'

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        selectedPlan: {
            type: Object,
            default: {}
        },
    },
    data(){
        return {
            confirmDowngrade: false,
            upgradeSuccess: false,
            downgradeFeedback: false,
            changingToPlan: null,
            loaders: {
                downgrade: false,
            },
            successAnimation: {
                animationData: launch,
                animationSpeed: 1,
                autoplay: true,
                renderer: 'svg',
                loop: true
            },
        }
    },
    computed: {
        ...mapState(useSiteStore, [
            'currentSite'
        ]),
        ...mapState(usePricingStore, [
            'popupPerks'
        ])
    },
    methods: {
        async changePlan() {
            return this.$router.push('/pricing')
        }
    }
}
